import { Providers } from "@/providers";

import { Router } from "@/components/Router";

export function App() {
  return (
    <Providers>
      <Router />
    </Providers>
  );
}
