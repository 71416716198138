import { createContext } from "react";

type MyContextType = {
  demo: boolean;
  setDemo: React.Dispatch<React.SetStateAction<boolean>>;
};

export const MyContext = createContext<MyContextType>({
  demo: false,
  setDemo: () => {},
});
