import { atom } from "jotai/index";

import { type Account } from "shared/apiClient";

export enum AuthFlow {
  Login = "Login",
  ForgotPassword = "ForgotPassword",
  NewPassword = "NewPassword",
  ResetPassword = "ResetPassword",
}

export const authFlowSelectedVar = atom<AuthFlow>(AuthFlow.Login);

export const isAuthenticatedVar = atom<boolean>(false);

export const accountVar = atom<Account | null>(null);

export const shouldReloadConversationsListVar = atom(false);
