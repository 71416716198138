import { StrictMode } from "react";
import ReactDOM from "react-dom/client";

import "shared/shared.css";
import "@/styles/globals.css";

import { App } from "./App.tsx";

import "@/lib/sentry";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
