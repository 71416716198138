import { lazy, Suspense, useEffect, useState } from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { useAtom, useSetAtom } from "jotai";

import { accountVar, isAuthenticatedVar } from "@/variables";

import { useGetAccount, useGetAuthTest, useLogout } from "shared/apiHooks";
import { useToast } from "shared/components";

const Layout = lazy(() => import("./Layout"));
const ConversationsPage = lazy(() => import("@/pages/ConversationsPage"));
const ConvDetailsPage = lazy(() => import("@/pages/ConvDetailsPage"));
const AuthenticationPage = lazy(() => import("@/pages/AuthPage"));
const ResetPasswordPage = lazy(() => import("@/pages/ResetPasswordPage"));
const DashboardPage = lazy(() => import("@/pages/DashboardPage"));

export interface ProtectedRouteProps {
  authTestOutcome: boolean | null;
  isAuthenticated: boolean;
  redirectPath?: string;
}

const ProtectedRoute = ({
  authTestOutcome,
  isAuthenticated,
  redirectPath = "/login",
}: ProtectedRouteProps) => {
  if (isAuthenticated) {
    return <Outlet />;
  }

  if (authTestOutcome === false) {
    return <Navigate to={redirectPath} replace />;
  }

  return null;
};

export function Router() {
  const [isAuthenticated, setIsAuthenticatedVar] = useAtom(isAuthenticatedVar);
  // @ts-ignore TODO: vite throws an error when building on this line
  const setAccountVar = useSetAtom(accountVar);
  const [authTestOutcome, setAuthTestOutcome] = useState<null | boolean>(null);

  const { toast } = useToast();

  const { isError, isSuccess } = useGetAuthTest({ retry: false });

  useEffect(() => {
    if (isSuccess) {
      setIsAuthenticatedVar(true);
      setAuthTestOutcome(true);
    } else if (isError) {
      setIsAuthenticatedVar(false);
      setAuthTestOutcome(false);
    }
  }, [isError, isSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isAuthenticated) {
      setAuthTestOutcome(false);
    }
  }, [isAuthenticated]);

  const { mutate: logout } = useLogout({
    onSuccess: () => {
      console.log("<Router> useLogout() onSuccess()");
      setIsAuthenticatedVar(false);
      toast({
        title: "Logged out",
        description:
          "You have been logged out as the user you were logged in as cannot access this application",
      });
    },
    onError: (error) => {
      console.error("<Router> useLogout() onError()", error);
    },
  });

  const { data: accountResponse } = useGetAccount({
    enabled: isAuthenticated,
  });

  useEffect(() => {
    console.log("<Router> accountResponse.data", accountResponse?.data);

    setAccountVar(accountResponse?.data || null);
  }, [accountResponse]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BrowserRouter>
      <Routes>
        <Route
          element={
            <ProtectedRoute
              authTestOutcome={authTestOutcome}
              isAuthenticated={isAuthenticated}
            />
          }
        >
          <Route index element={<Navigate to="/conversations" />} />
        </Route>
        <Route
          element={
            <ProtectedRoute
              authTestOutcome={authTestOutcome}
              isAuthenticated={isAuthenticated}
            />
          }
        >
          <Route
            path="conversations"
            element={
              <Suspense>
                <Layout>
                  <ConversationsPage />
                </Layout>
              </Suspense>
            }
          />
        </Route>
        <Route
          element={
            <ProtectedRoute
              authTestOutcome={authTestOutcome}
              isAuthenticated={isAuthenticated}
            />
          }
        >
          <Route
            path="convdetails/:convId"
            element={
              <Suspense>
                <Layout>
                  <ConvDetailsPage />
                </Layout>
              </Suspense>
            }
          />
        </Route>
        <Route
          element={
            <ProtectedRoute
              authTestOutcome={authTestOutcome}
              isAuthenticated={isAuthenticated}
            />
          }
        >
          <Route
            path="/dashboard"
            element={
              <Suspense>
                <Layout>
                  <DashboardPage />
                </Layout>
              </Suspense>
            }
          />
        </Route>
        <Route
          path="login"
          element={
            <Suspense>
              <Layout>
                <AuthenticationPage />
              </Layout>
            </Suspense>
          }
        />
        <Route
          path="reset-password"
          element={
            <Suspense>
              <Layout>
                <ResetPasswordPage />
              </Layout>
            </Suspense>
          }
        />
        <Route path="*" element={<p>There's nothing here: 404!</p>} />
      </Routes>
    </BrowserRouter>
  );
}
